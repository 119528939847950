import React, { useContext, useEffect, useState } from 'react';
import { KeyValuePair } from 'helpers';
import { applicationContext, authContext } from 'helpers/services';

interface ISupportContext {
    liveHelpLinks: ILiveHelp[]
    liveHelpCountries: KeyValuePair<string, string>[]
    selectedCountry: string
    isLoading: boolean

    updateCurrentCountry: (newCountry: string) => void
}

interface ILiveHelp {
    country: string
    links: ILink[]
}

interface ILink {
    brand: string
    vitecBrand: string
    url: string
}

export let supportContext = React.createContext({} as ISupportContext);

let { Provider } = supportContext;

let SupportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let appService = useContext(applicationContext);
    let authService = useContext(authContext);

    let [isLoading, setLoading] = useState(true);
    let [selectedCountry, setSelectedCountry] = useState<string>('');

    let [liveHelpCountries] = useState<KeyValuePair<string, string>[]>([
        new KeyValuePair<string, string>('AU', 'Australia'),
        new KeyValuePair<string, string>('BE', 'Belgium'),
        new KeyValuePair<string, string>('CN', 'China'),
        new KeyValuePair<string, string>('DE', 'Germany'),
        new KeyValuePair<string, string>('FR', 'France'),
        new KeyValuePair<string, string>('IT', 'Italy'),
        new KeyValuePair<string, string>('IE', 'Ireland'),
        new KeyValuePair<string, string>('JP', 'Japan'),
        new KeyValuePair<string, string>('NL', 'Netherlands'),
        new KeyValuePair<string, string>('GB', 'United Kingdom'),
        new KeyValuePair<string, string>('US', 'United States'),
        new KeyValuePair<string, string>('', 'Other country')
    ]);


    useEffect(() => {
        if (authService.isLoading === false)
            prepareComponent();
    }, [authService.isLoading])


    let prepareComponent = async () => {
        setLoading(true);

        let country = appService.currentCountry;
        if (authService.isAuthenticated && authService.currentUser) {
            country = authService.currentUser.countryCode;
        }

        if (liveHelpCountries.findIndex(x => x.key === appService.currentCountry) !== -1)
            setSelectedCountry(country);

        setLoading(false);
    }

    let [liveHelpLinks] = useState<ILiveHelp[]>([
        {
            country: 'US',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/us-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/us-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/us-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/us-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/us-en/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/us-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/us-en/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/us-en/customer-service/warranty-terms-and-conditions/' },
                { brand: 'SAV', vitecBrand: 'SAV', url: 'https://new.savageuniversal.com/global/customer-service/contact-us/#reason=Warranty' }
            ]
        },
        {
            country: 'GB',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/uk-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/uk-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/uk-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/uk-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/uk-en/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/uk-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/uk-en/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/uk-en/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'IE',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/ie-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/ie-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/ie-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/ie-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/global/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/ie-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/global/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/uk-en/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'IT',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/it-it/customer-service/contattaci/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/it-it/customer-service/contattaci/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/it-it/customer-service/contattaci/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/it-it/customer-service/contattaci/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/it-it/customer-service/contattaci/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/it-it/customer-service/contattaci/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/it-it/customer-service/contattaci/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/it-it/customer-service/garanzia-termini-condizioni/' },
            ]
        },
        {
            country: 'DE',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/de-de/customer-service/kontakt/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/de-de/customer-service/kontakt/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/de-de/customer-service/kontakt/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/de-de/customer-service/kontakt/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/de-de/customer-service/kontaktieren-sie-uns/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/de-de/customer-service/kontakt/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/de-de/customer-service/kontakt/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/de-de/customer-service/garantiebestimmungen/' },
            ]
        },
        {
            country: 'FR',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/fr-fr/customer-service/contactez-nous/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/fr-fr/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'NL',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/nl-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/nl-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/nl-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/nl-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/nl-en/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/nl-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/nl-en/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/nl-en/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'BE',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/be-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/be-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/be-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/be-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/be-en/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/be-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/be-en/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/be-en/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'JP',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/jp-ja/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/jp-ja/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/jp-ja/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/jp-ja/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/jp-ja/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/jp-ja/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/jp-ja/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/jp-ja/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'AU',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/au-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/au-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/au-en/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/au-en/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/au-en/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/au-en/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/au-en/support/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/au-en/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: 'CN',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/cn-zh/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/cn-zh/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/cn-zh/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/cn-zh/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/cn-zh/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/cn-zh/support/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/cn-zh/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/cn-zh/customer-service/warranty-terms-and-conditions/' },
            ]
        },
        {
            country: '',
            links: [
                { brand: 'MANF', vitecBrand: 'MNF', url: 'https://www.manfrotto.com/global/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'AVE', url: 'https://www.manfrotto.com/global/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'NG', url: 'https://www.manfrotto.com/global/customer-service/contact-us/' },
                { brand: 'MANF', vitecBrand: 'COL', url: 'https://www.manfrotto.com/global/customer-service/contact-us/' },
                { brand: 'JOBY', vitecBrand: 'JBY', url: 'https://joby.com/global/customer-service/contact-us/' },
                { brand: 'GITZO', vitecBrand: 'GTZ', url: 'https://www.gitzo.com/global/customer-service/contact-us/' },
                { brand: 'LOWE', vitecBrand: 'LWP', url: 'https://www.lowepro.com/global/customer-service/contact-us/' },
                { brand: 'SYRP', vitecBrand: 'BSY', url: 'https://syrp.co/global/customer-service/warranty-terms-and-conditions/' },
                { brand: 'SAV', vitecBrand: 'SAV', url: 'https://new.savageuniversal.com/global/customer-service/contact-us/#reason=Warranty/' },
            ]
        }
    ]);


    let updateCurrentCountry = (newCountry: string) => {
        setSelectedCountry(newCountry);
    }


    return (
        <Provider value={{
            liveHelpLinks,
            liveHelpCountries,
            selectedCountry,
            isLoading,

            updateCurrentCountry
        }}>
            {children}
        </Provider>
    );
}

export default SupportProvider;